export const PasswordIcon = () => {
    return (
        <svg
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.9067 0.830078C6.07607 0.830078 1.9507 3.94008 0.279297 8.33008C1.9507 12.7201 6.07607 15.8301 10.9067 15.8301C15.7422 15.8301 19.8627 12.7201 21.5341 8.33008C19.8627 3.94008 15.7422 0.830078 10.9067 0.830078ZM10.9067 13.3301C8.2402 13.3301 6.07607 11.0901 6.07607 8.33008C6.07607 5.57008 8.2402 3.33008 10.9067 3.33008C13.5732 3.33008 15.7374 5.57008 15.7374 8.33008C15.7374 11.0901 13.5732 13.3301 10.9067 13.3301ZM10.9067 5.33008C9.30777 5.33008 8.00833 6.67508 8.00833 8.33008C8.00833 9.98508 9.30777 11.3301 10.9067 11.3301C12.5057 11.3301 13.8051 9.98508 13.8051 8.33008C13.8051 6.67508 12.5057 5.33008 10.9067 5.33008Z"
                fill="#D7D9D7"
            />
        </svg>
    );
};